<!--
 * @Author: your name
 * @Date: 2021-11-08 09:34:58
 * @LastEditTime: 2022-03-22 15:02:35
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \web-pc\src\views\newsList\detail.vue
-->
<template>
    <div class="news-detail">
        <div class="news-detail-header">
            <Nav></Nav>
        </div>
        <div class="new-detail-main">
            <div class="title">{{ detail.newsSubject }}</div>
            <div class="operation">
                <el-button type="primary" v-if="this.newIndex != 1" @click="lastNews()">上一条</el-button>
                <el-button type="primary" v-if="this.newIndex < this.sortList.length" @click="nextNews()">下一条</el-button>
            </div>
            <div class="time">
                <span style="margin-right: 32px">发布者: {{ detail.creatorName ? detail.creatorName : '' }}</span>
                <span style="margin-right: 32px">发表时间: {{ detail.publishTime }}</span>
                <span>阅读量: {{ detail.readNum }}</span>
            </div>
            <div class="content" v-html="detail.newsContent"></div>
        </div>
    </div>
</template>

<script>
import Nav from '@/components/nav.vue';
import Api from '@/api/news';
export default {
    components: { Nav },
    data() {
        return {
            detail: {},
            sortList: [],
            newIndex: 1,
        };
    },
    methods: {
        requestDetail() {
            if (this.$route.query.id) {
                this.id = this.$route.query.id;
                Api.getDetail(this.id)
                    .then(res => {
                        this.detail = res.data;
                        console.warn(res);
                    })
                    .catch(err => {
                        console.warn(err);
                    });
            }
        },
        requestSortList() {
            let params = {
                newsTypeCode: 'news',
            };
            Api.getNewsList(params)
                .then(res => {
                    this.sortList = res.data;
                    if (this.$route.query.id) {
                        this.id = this.$route.query.id;
                        this.sortList.forEach(item => {
                            if (item.id == this.id) {
                                this.newIndex = item.newIndex;
                            }
                        });
                    }
                    console.warn(res);
                })
                .catch(err => {
                    console.warn(err);
                });
        },
        lastNews() {
            if (this.newIndex != 1) {
                this.newIndex = this.newIndex - 1;
                this.$route.query.id = this.sortList[this.newIndex - 1].id;
                this.requestDetail();
                this.$forceUpdate();
            }
        },
        nextNews() {
            if (this.newIndex < this.sortList.length) {
                this.newIndex = this.newIndex + 1;
                this.$route.query.id = this.sortList[this.newIndex - 1].id;
                this.requestDetail();
                this.$forceUpdate();
            }
        },
        $init() {
            this.requestDetail();
            this.requestSortList();
        },
    },
    created() {
        this.$init();
    },
};
</script>
<style lang="scss" scoped>
.news-detail {
    min-height: 80vh;
    .news-detail-header {
        height: 86px;
    }
    .new-detail-main {
        .title {
            width: 100%;
            text-align: center;
            // color: #4169e1;
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 32px;
        }
        .operation {
            width: 1300px;
            margin: 16px auto;
            display: flex;
            justify-content: flex-end;
        }
        .time {
            text-align: center;
            height: 25px;
            line-height: 25px;
            vertical-align: middle;
            line-height: 1.5em;
            padding: 0 5px;
            color: #969696;
            overflow: hidden;
        }
        .content {
            width: 1300px;
            margin: 74px auto;
            font-family: 微软雅黑;
            text-align: justify;
        }
    }
}
</style>