/*
 * @Author: your name
 * @Date: 2021-09-22 10:02:54
 * @LastEditTime: 2021-11-09 16:29:33
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \web-admin\src\api\settings.js
 */

import { axios } from './../plugin/axios'
export default {
    getList(params) {
        return axios.post(`/api/base/surpriseNews/web/list`, params)
    },
    getDetail(id) {
        return axios.get(`/api/base/surpriseNews/${id}/detail`)
    },
    getNewsList(params) {
        return axios.post(`/api/base/surpriseNews/web/sort-list`,params)
    }

}